import "./styles/main.less";
import "virtual:windi-components.css";
import "virtual:windi-utilities.css";
import "ant-design-vue/dist/reset.css";
import "vant/lib/index.css";

import Antd from "ant-design-vue";
import { ImagePreview } from "vant";
import { createApp } from "vue";

import router from "@/router";

import App from "./App.vue";

const app = createApp(App);
app.use(Antd);
app.use(router);
app.use(ImagePreview);
app.mount("#app");
