import type { RouteRecordRaw } from "vue-router";

import audioPng from "@/assets/layout/audio.png";
import bookPng from "@/assets/layout/book.png";
import documentPng from "@/assets/layout/document.png";
import imagePng from "@/assets/layout/image.png";
import networkPng from "@/assets/layout/network.png";
import textPng from "@/assets/layout/text.png";
import videoPng from "@/assets/layout/video.png";
import { generateRouteNavs } from "@/utils/navs";

const homeChildren: RouteRecordRaw[] = [
  {
    path: "/home/text",
    name: "Text",
    meta: {
      name: "文本",
      format: true,
      icon: textPng,
    },
    component: () => import("@/views/home/views/text/index.vue"),
  },
  {
    path: "/home/network",
    name: "network",
    meta: {
      name: "网址",
      format: true,
      icon: networkPng,
    },
    component: () => import("@/views/home/views/network/index.vue"),
  },
  {
    path: "/home/file",
    name: "file",
    meta: {
      name: "文件",
      format: true,
      icon: documentPng,
    },
    component: () => import("@/views/home/views/file/index.vue"),
  },
  {
    path: "/home/image",
    name: "image",
    meta: {
      name: "图片",
      format: true,
      icon: imagePng,
    },
    component: () => import("@/views/home/views/image/index.vue"),
  },
  {
    path: "/home/audio",
    name: "audio",
    meta: {
      name: "音频",
      format: true,
      icon: audioPng,
    },
    component: () => import("@/views/home/views/audio/index.vue"),
  },
  {
    path: "/home/video",
    name: "video",
    meta: {
      name: "视频",
      format: true,
      icon: videoPng,
    },
    component: () => import("@/views/home/views/video/index.vue"),
  },
  {
    path: "/home/book",
    name: "book",
    meta: {
      name: "电子画册",
      format: true,
      icon: bookPng,
    },
    component: () => import("@/views/home/views/book/index.vue"),
  },
];

export const publicNavs = generateRouteNavs(homeChildren);

export const homeRoute: RouteRecordRaw[] = [
  {
    path: "/home",
    redirect: homeChildren[0].path,
  },
  {
    path: "/home",
    name: "Home",
    meta: {
      name: "首页",
    },
    component: () => import("@/views/home/index.vue"),
    children: homeChildren,
  },
  {
    path: "/vip",
    name: "VIP",
    meta: {
      name: "VIP会员",
      hot: true,
    },
    component: () => import("@/views/vip/index.vue"),
  },
  {
    path: "/active/:key",
    name: "active",
    component: () => import("@/views/active/index.vue"),
  },
  {
    path: "/:catchAll(.*)",
    name: "notFound",
    component: () => import("@/views/NotFound/index.vue"),
  },
];
