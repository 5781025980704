import type { RouteRecordRaw } from "vue-router";

import { generateRouteNavs } from "@/utils/navs";

export const adminRoute: RouteRecordRaw[] = [
  {
    path: "/admin",
    name: "admin",
    meta: {
      name: "管理后台",
      needLogin: true,
    },
    component: () => import("@/layout/adminLayout.vue"),
    children: [
      {
        path: "/admin",
        name: "home",
        meta: {
          name: "首页",
        },
        component: () => import("@/views/admin/home/index.vue"),
      },
      {
        path: "/admin/qrcode",
        name: "qrcode",
        meta: {
          name: "二维码",
        },
        component: () => import("@/views/admin/qrcode/index.vue"),
      },
    ],
  },
];

export const adminNavs = generateRouteNavs(adminRoute);
