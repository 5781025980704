import type { RouteRecordRaw } from "vue-router";

import { generateRouteNavs } from "@/utils/navs";

export const helpRoutes: RouteRecordRaw[] = [
  {
    path: "/help",
    name: "help",
    meta: {
      name: "帮助",
    },
    component: () => import("@/layout/helpLayout.vue"),
    children: [],
  },
];

export const helpNavs = generateRouteNavs(helpRoutes);
