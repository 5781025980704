
// import { createRouter, createWebHashHistory } from "vue-router";
import { createRouter, createWebHashHistory } from "vue-router";

// import { createRouter, createWebHistory  } from "vue-router";
import { adminNavs, adminRoute } from "./admin";
import { helpNavs, helpRoutes } from "./help";
import { publicNavs, publicRoute } from "./public";
import { superNavs, superRoutes } from "./super";

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    {
      path: "/",
      redirect: publicRoute[0].path,
    },
    ...publicRoute,
    ...adminRoute,
    ...superRoutes,
    ...helpRoutes,
  ],
});

router.beforeEach((to, _form, next) => {
  document.title =
    to.meta.format && to.meta.name
      ? `${to.meta.name}二维码生成器_${to.meta.name}二维码在线制作_阿拉丁二维码`
      : to.meta.name || "";
  next();
});

const navs = [...publicNavs, ...helpNavs];

export default router;
export { adminNavs, navs, superNavs };
