import { isEmpty } from "lodash-es";
import type { RouteRecordRaw } from "vue-router";

export const generateRouteNavs = (routes: RouteRecordRaw[]) => {
  return routes
    .map((item: any) => ({
      path: item.path,
      name: item.meta?.name || "",
      needLogin: item.meta?.needLogin || false,
      hot: item.meta?.hot || false,
      icon: item.meta?.icon as any,
    }))
    .filter((item) => !isEmpty(item.name));
};
