import type { RouteRecordRaw } from "vue-router";

import { generateRouteNavs } from "@/utils/navs";

export const superRoutes: RouteRecordRaw[] = [
  {
    path: "/super",
    name: "super",
    meta: {
      name: "管理后台",
      needLogin: true,
    },
    component: () => import("@/layout/superAdminLayout.vue"),
    children: [
      {
        path: "",
        redirect: "/super/userList",
      },
      {
        path: "/super/userList",
        name: "userList",
        meta: {
          name: "用户列表",
        },
        component: () => import("@/views/super/UserList/index.vue"),
      },
      {
        path: "/super/content",
        name: "content",
        meta: {
          name: "内容查询",
        },
        component: () => import("@/views/super/Content/index.vue"),
      },
    ],
  },
];

export const superNavs = generateRouteNavs(superRoutes);
