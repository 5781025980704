import type { RouteRecordRaw } from "vue-router";

import { generateRouteNavs } from "@/utils/navs";

import { homeRoute } from "./home";

const publicRouteChildren = [...homeRoute];

export const publicNavs = generateRouteNavs(publicRouteChildren);

export const publicRoute: RouteRecordRaw[] = [
  {
    path: "/public",
    redirect: homeRoute[0].path,
  },
  {
    path: "/public",
    name: "Public",
    component: () => import("@/layout/layout.vue"),
    children: [...publicRouteChildren],
  },
  {
    path: "/dynamicText/:uuid",
    name: "dynamicText",
    component: () => import("@/views/result/dynamicResult.vue"),
  },
  {
    path: "/qrcodeResultImage/:uuid",
    name: "qrcodeResultImage",
    component: () => import("@/views/result/images.vue"),
  },
  {
    path: "/filePreview/:uuid",
    name: "filePreview",
    component: () => import("@/views/result/filePreview.vue"),
  },
  {
    path: "/fileResult/:url",
    name: "fileResult",
    component: () => import("@/views/result/fileResult.vue"),
  },
  {
    path: "/fileVideo/:url",
    name: "fileVideo",
    component: () => import("@/views/result/fileVideo.vue"),
  },
  {
    path: "/fileAudio/:url",
    name: "fileAudio",
    component: () => import("@/views/result/fileAudio.vue"),
  },
  {
    path: "/imagePreview/:uuid",
    name: "imagePreview",
    component: () => import("@/views/result/imagePreview.vue"),
  },
  {
    path: "/textPreview/:uuid",
    name: "textPreview",
    component: () => import("@/views/result/textPreview.vue"),
  },
  {
    path: "/bookPreview/:uuid",
    name: "bookPreview",
    component: () => import("@/views/result/bookPreview.vue"),
  },
  {
    path: "/overTime",
    name: "overTime",
    component: () => import("@/views/result/overTime.vue"),
  },
];
